import Vue from 'vue';
import VueCodemirror from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/cobalt.css';
// import dedent from 'dedent';
// language
import 'codemirror/mode/xml/xml';
// require active-line.js
import 'codemirror/addon/selection/active-line';
// autoCloseTags
import 'codemirror/addon/edit/closetag';

Vue.use(VueCodemirror);
