<template>
    <div class="upload-state">
        <img :src="result">
        <div class="upload-process">
            <strong>{{ file.name }}</strong>
            <div class="upload-progress-bar">
                <div class="upload-percent-back" />
                <div ref="progress" class="upload-percent-front" />
            </div>
        </div>
        <div class="upload-control">
            <button type="button" class="upload-remove" id="remove-upload">
                <span class="ech-icon icon-trash" />
            </button>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import imageCropper from './imageCropper';

export default {
    props: {
        file: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        crop: {
            type: Object,
            required: true,
            default: () => ({}),
        },
    },

    data () {
        return {
            result: null,
        };
    },
    watch: {
        file () {
            this.getImage();
        },
        result (imageData) {
            if (this.crop.enable) {
                return this.appendCropper(imageData);
            }

            return this.upload();
        },
    },
    beforeMount () {
        this.getImage();
    },
    methods: {
        handler (data, callback) {
            this.upload(data);
        },
        appendCropper (imageData) {
            const Cropper = Vue.extend(imageCropper);

            const instance = new Cropper({
                propsData: {
                    imageData: imageData,
                    handler: this.handler,
                    dom: this.crop.ref,
                },
            });

            instance.$mount();

            const container = document.querySelector(this.crop.ref);

            container.appendChild(instance.$el);
        },
        getImage () {
            const reader = new FileReader();

            reader.onload = function () {
                this.result = reader.result;
            }.bind(this);

            reader.readAsDataURL(this.file);
        },
        upload () {
            let m = 0;
            const x = setInterval(() => {
                if (m >= 100) {
                    clearInterval(x);
                    return;
                }

                this.$refs.progress.style.width = `${++m}%`;
            }, 100);
        },
    },
};
</script>
