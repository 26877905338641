<template>
    <button :type="type"
            @click="$emit('click', $event)"
            class="btn"
            :disabled="isDisabled"
            :data-test-id="dataTestId"
            id="id"
    >
        <i v-if="$slots.icon && !loading" class="icon-slot">
            <slot name="icon" />
        </i>
        <i v-if="loading" class="lottie-loading">
            <ech-lottie v-if="!isDisabled"
                        should-display
                        animation-name="loadingAnimation"
            />
            <ech-lottie v-else
                        should-display
                        animation-name="loadingDisabledAnimation"
            />
        </i>
        <slot />
        <span class="align-middle">
            {{ text }}
        </span>
    </button>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'button',
        },
        text: {
            type: String,
            default: 'Click Me',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        default: {
            type: Boolean,
            default: true,
        },
        dataTestId: {
            type: String,
            default: 'btn-test-cypress',
        },
        id: {
            type: String,
            default: 'default-ech-button',
        },
    },
    computed: {
        isDisabled () {
            if (this.loading) return true;
            return this.disabled;
        },
    },
};
</script>

<style lang="scss">
.btn {
    position: relative;

    .lottie-loading {
        width: 25px;
        height: 25px;
        display: inline-block;
        vertical-align: middle;
    }

    &-lg {

        .lottie-loading {
            position: absolute;
            left: 10px;
            width: 32px;
            height: 32px;
        }
    }

    .icon-slot {
        margin-right: 10px;
        vertical-align: middle;
    }
}
</style>
