<template>
    <transition name="alert-slide">
        <div class="ech-alert"
             v-if="showAlert"
        >
            <div class="alert-content d-flex align-items-center"
                 ref="close"
                 @click="close"
            >
                <div v-if="alertContent.type === 'danger'"
                     class="danger-icon"
                >
                    <error-icon />
                </div>
                <div v-if="alertContent.type === 'info'"
                     class="info-icon"
                >
                    <info-icon />
                </div>
                <div v-if="alertContent.type === 'warning'"
                     class="warning-icon"
                >
                    <warning-icon />
                </div>
                <div class="matn matn__simple less-height white ml-3">
                    {{ alertContent.text }}
                </div>
                <div class="ml-auto close-icon">
                    <close-icon />
                </div>
            </div>
            <div class="bottom-progress-bar"
                 :class="progressbarColour"
            />
        </div>
    </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ErrorIcon from '~/assets/icons/error';
import InfoIcon from '~/assets/icons/info';
import WarningIcon from '~/assets/icons/warning';
import CloseIcon from '~/assets/icons/delete';

export default {
    components: {
        ErrorIcon,
        InfoIcon,
        WarningIcon,
        CloseIcon,
    },
    data () {
        return {
            timeoutId: '',
        };
    },
    computed: {
        ...mapState(['showAlert', 'alertContent']),

        progressbarColour () {
            switch (this.alertContent.type) {
            case 'danger':
                return 'general-red__background';
            case 'info':
                return 'blue-100__background';
            case 'warning':
                return 'yellow-100__background';
            default:
                return 'general-red__background';
            }
        },
    },
    watch: {
        showAlert (value) {
            if (value) {
                this.timeoutId = setTimeout(() => {
                    this.TOGGLE_ALERT(false);
                }, this.alertContent.duration);
            }
        },
    },
    methods: {
        ...mapMutations(['TOGGLE_ALERT']),

        close () {
            clearTimeout(this.timeoutId);
            this.TOGGLE_ALERT(false);
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

.ech-alert {
    color: white;
    cursor: pointer;
    width: 385px;
    height: 96px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: rgba(26, 22, 22, 0.8);
    position: fixed;
    top: 120px;
    right: 24px;
    z-index: 1000001;

    .alert-content {
        padding-left: 26px;
        padding-right: 27px;
        height: 88px;

        .danger-icon {

            svg {

                g {
                    fill: $general-red;
                }
            }
        }

        .info-icon {

            svg {

                g {
                    fill: $blue-100;
                }
            }
        }

        .warning-icon {

            svg {

                g {
                    fill: $yellow-100;
                }
            }
        }

        .close-icon {

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .bottom-progress-bar {
        height: 8px;
        animation: progress 5000ms linear;
        transition: width 5000ms;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

@keyframes progress {

    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}
</style>
