<template>
    <div class="form-group input-toggle" :class="{
        'has-error' : error
    }"
    >
        <div v-if="label">
            <label class="label">{{ label }} <span v-if="!required" class="optional">Optional</span></label>
            <info-icon v-if="info" v-b-tooltip.hover.topleft title="Refer to the Settings to change the currency unit." />
        </div>
        <div class="input-toggle_radio d-flex align-items-center border rounded px-2 py-1">
            <div class="d-flex align-items-center align-self-stretch rounded" v-for="(item, index) in toggleItems"
                 :key="index"
                 :class="['flex-grow-1 text-center',
                          value === item.value ? 'input-toggle--selected text-white' : 'text-muted',
                          index > toggleItems.length ? 'mr-2' : '']"
            >
                <input
                    class="d-none form-control input"
                    :name="groupName"
                    :id="`${item.name} - ${index}`"
                    type="radio"
                    :value="item.value"
                    :checked="shouldCheck(value, item.value)"
                    @change="update(item.value)"
                >
                <label class="text-center col-12 input-toggle--label"
                       :for="`${item.name} - ${index}`"
                >
                    {{ item.name }}
                </label>
            </div>
        </div>
        <span v-show="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>

import isEqual from 'lodash/isEqual';

export default {
    props: {
        toggleItems: {
            type: Array,
            default: null,
        },
        groupName: {
            type: String,
            default: 'radio-group-name',
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [Boolean, String, Number, Object],
            default: null,
        },
        info: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: true,
        },
        error: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            content: this.value,
        };
    },
    methods: {
        update (value) {
            this.content = value;
            this.$emit('input', this.content);
        },
        shouldCheck (firstValue, secondValue) {
            return isEqual(firstValue, secondValue);
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

.input-toggle {

    &_radio {
        min-height: 40px;
        min-width: 120px;
    }

    &--selected {
        background: $secondary-text-100;
    }
}

</style>
