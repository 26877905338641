<template>
    <div>
        <label v-if="label.length">{{ label }}</label>
        <VueEditor
            v-model="content"
        />
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            content: '',
        };
    },
    watch: {
        content () {
            this.$emit('input', this.content);
        },
    },
    mounted () {
        this.content = this.value;
    },
    methods: {

    },
};
</script>
