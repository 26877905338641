import Vue from 'vue';
import Sortable from 'sortablejs';
import get from 'lodash/get';

Vue.directive('sortable', {
    update (el, options = {}) {
        const sortable = get(options, 'value.sort', true);
        if (sortable) Sortable.create(el, options);
    },
});
