<template>
    <div class="ech-input-radio" :class="{ 'd-flex align-items-center': horizontal, 'row d-flex': hasImages}">
        <!-- IMAGE LABELS -->
        <template v-if="hasImages">
            <div class="col-2">
                <div v-for="(item, index) in list"
                     :key="index"
                >
                    <img v-if="item.image"
                         :src="$image(item.image.path)"
                         :class="item.image.className"
                    >
                </div>
            </div>
        </template>
        <label class="text small dark"
               v-if="label"
        >
            {{ label }}
        </label>
        <div :class="[{'col-10': hasImages}, {'d-flex align-items-center': horizontal}]">
            <div @click="update(item[valueObjectKey])"
                 v-for="(item, key) in list"
                 :key="key"
                 class="d-flex align-items-center ech-radio-container position-relative text-md text-semi-dark"
                 :class="{'pr-3': horizontal}"
            >
                {{ item.label }}
                <input type="radio"
                       ref="radioref"
                       :id="id"
                       :name="name"
                       :value="item[valueObjectKey]"
                       :checked="value === item[valueObjectKey]"
                       @change="update(item[valueObjectKey])"
                >
                <div class="check" />
                <div class="pointable">
                    <label :for="id"
                           class="text small dark pointable not-selectable"
                    >
                        {{ item['title'] }}
                    </label>
                    <p v-if="item.description"
                       unselectable="on"
                       class="radio-item-description matn matn__small-fade w-75 mb-0"
                    >
                        {{ item.description }}
                    </p>
                    <div v-if="list.length > 1 && hasOrSeparator" class=" text or-separator">
                        <div v-if="key === list.length - 1" class="or-separator__text">
                            Or
                        </div>
                        <div class="or-separator__line" />
                    </div>
                </div>
            </div>
        </div>
        <p class="d-block secondary-text-50 text tiny" v-if="description">
            {{ description }}
        </p>

        <div class="slot slot-active">
            <slot name="extra" />
        </div>
        <span class="error-message text mid f-std-book fw-normal red">
            {{ error }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'radio_name',
            required: true,
        },
        list: {
            type: Array,
            default: () => ([]),
        },
        error: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        valueObjectKey: {
            type: String,
            default: 'value',
        },
        horizontal: {
            type: Boolean,
            default: false,
        },
        extraId: {
            type: [String, Number],
            default: 0,
        },
        hasOrSeparator: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Object, Number, Boolean],
            required: true,
        },
    },
    computed: {
        hasImages () {
            return this.list.some(e => {
                return {}.propertyIsEnumerable.call(e, 'image');
            });
        },
        id () {
            return 'radio_' + this._uid;
        },
    },
    methods: {
        update (val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

.ech-radio-container {

    .or-separator {
        color: $divider;
        font-size: 14px;

        &__text {
            position: absolute;
            left: 0;
            bottom: -8px;
        }

        &__line {
            height: 1px;
            background-color: $divider;
        }
    }
}
</style>
