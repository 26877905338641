<i18n>
{
    "en": {
        "profile": "Profile",
        "userSettings": "User Settings",
        "logout": "Logout"
    }
}
</i18n>

<template>
    <div class="header">
        <strong class="header-title text f-std-bold text-capitalize">
            {{ pageTitle }}
        </strong>
        <div class="header-extra">
            <a data-test-id="notificationsign"
               class="button notifications"
            />
            <b-dropdown id="dropdown-offset"
                        :text="clientNameShort"
                        data-test-id="profile-pic"
                        offset="-185px"
                        class="button profile-toggler"
            >
                <b-dropdown-item v-if="clientName" class="username" href="#">
                    {{ clientName }}
                </b-dropdown-item>

                <b-dropdown-item class="email" href="#">
                    {{ profile && profile.email ? profile.email : '' }}
                </b-dropdown-item>

                <nuxt-link class="dropdown-item" to="/profile">
                    {{ $t('profile') }}
                </nuxt-link>

                <nuxt-link class="dropdown-item" to="/settings">
                    {{ $t('userSettings') }}
                </nuxt-link>

                <b-dropdown-divider />

                <b-dropdown-item
                    class="logout"
                    href="#"
                    @click="logout"
                >
                    {{ $t('logout') }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            pageTitle: s => s.pageTitle,
        }),
        ...mapState(['profile']),

        clientName () {
            const { profile } = this;
            return profile ? [profile.givenName, profile.familyName].filter(Boolean).join(' ') : '';
        },
        clientNameShort () {
            const { profile } = this;
            if (profile) {
                if (profile.givenName && profile.familyName) {
                    return profile.givenName[0].toUpperCase() + profile.familyName[0].toUpperCase();
                } else if (profile.givenName) {
                    return profile.givenName[0].toUpperCase();
                }
            }
            return 'N/A';
        },
    },
    methods: {
        async logout () {
            await this.$apolloHelpers.onLogout();
            this.$router.push('/login');
        },
    },
};
</script>
