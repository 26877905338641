<template>
    <div class="switch-button-control">
        <div class="switch-button" :class="{ enabled: isEnabled}" @click="toggle" :style="{'--color': color}">
            <div class="button" />
        </div>
        <div class="switch-button-label">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'isEnabled',
        event: 'toggle',
    },
    props: {
        isEnabled: Boolean,
        color: {
            type: String,
            required: false,
            default: '#FFF',
        },
    },
    methods: {
        toggle: function () {
            this.$emit('toggle', !this.isEnabled);
        },
    },
};
</script>
