<template>
    <div>
        <nuxt />
        <ech-alert />
    </div>
</template>

<script>
import EchAlert from '~/components/common/ech-alert';

export default {
    components: {
        EchAlert,
    },
    head () {
        return {
            htmlAttrs: {
                class: [
                    this.$store.state.device.apple && 'apple-device',
                ].filter(Boolean).join(' '),
            },
            bodyAttrs: {
                class: [
                    this.$store.state.device.android && 'android-device',
                    this.$store.state.device.apple && 'apple-device',
                    this.$store.state.device.tablet && 'tablet-device',
                    this.$store.state.device.mobile && 'mobile',
                ]
                    .filter(Boolean)
                    .join(' '),
            },
        };
    },
};
</script>
