<template>
    <li
        class="menu"
        :class="{ 'active': current || active, 'disabled': disabled }"
    >
        <a class="item" @click.prevent="clickHandler" v-if="!hasUri">
            <span class="icon" v-if="checkedByState"
                  :class="passed ? menu.icons.active : menu.icons.deactive"
            />
            <span class="icon" v-if="!checkedByState"
                  :class="menu.icons.active"
            />
            <span class="text">
                {{ menu.title }}
            </span>
        </a>

        <nuxt-link v-if="hasUri" :to="menu.uri" class="item">
            <span class="icon"
                  :class="menu.icons.active"
            />

            <span class="text">
                {{ menu.title }}
            </span>
        </nuxt-link>
    </li>
</template>

<script>
export default {
    props: {
        menu: {
            type: Object,
            default: () => {},
        },
        checkedByState: {
            type: Boolean,
        },
        index: {
            type: Number,
            default: 0,
        },
        callback: {
            type: Function,
            default: () => {},
        },
        current: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
        passed: {
            type: Boolean,
        },
    },
    computed: {
        hasUri () {
            // eslint-disable-next-line no-prototype-builtins
            return this.menu.hasOwnProperty('uri');
        },
        active () {
            return this.$route.path === this.menu.uri;
        },
    },
    methods: {
        clickHandler () {
            if (!this.disabled) {
                this.callback(this.index, true);
            }
        },
    },
};
</script>
