<template>
    <div class="input-checkbox" :class="className">
        <input :id="id" type="checkbox"
               v-show="false"
               :name="name"
               :disabled="disabled"
               :checked="value"
               @change="update"
               ref="checkbox"
        >

        <label :for="id"
               :data-test-id="dataTestId"
        >
            <span class="checkbox"
                  :class="{ 'not-pointable': disabled }"
            />
            <span class="text small not-selectable"
                  :class="[
                      disabled ? 'text gray not-pointable' : 'pointable',
                      value ? 'main-color' : 'text gray'
                  ]"
            >
                <div :data-test-id="`cypress-test-${name.toLowerCase().replace(/ /g, '-')}`">
                    {{ label }}
                </div>
            </span>
        </label>

        <p class="d-block not-selectable matn matn__small-fade"
           :class="descriptionClass"
           v-if="description"
           @click="$refs.checkbox.click()"
        >
            {{ description }}
        </p>
        <span class="error-message">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        dataTestId: {
            type: String,
            default: 'checkbox',
        },
        label: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'checkbox_input',
            required: true,
        },
        className: {
            type: String,
            default: 'ech-checkbox',
        },
        value: {
            type: [Boolean, String],
            default: false,
        },
        error: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        descriptionClass: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            id: null,
            checked: this.value,
        };
    },
    mounted () {
        this.id = 'checkbox-' + this._uid;
    },
    methods: {
        update ($event) {
            if (!this.disabled) {
                this.$emit('input', $event.target.checked);
            }
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

.ech-checkbox-container {
    height: 25px;
    display: flex;
    justify-content: left;
    position: relative;
    padding-left: 28px !important;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'CircularStdBook', sans-serif;

    &:hover, &.checked {
        color: #1C1717;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .ech-checkbox, .ech-checkbox-site {
        position: absolute;
        top: 2px;
        left: 1px;
        height: 16px;
        width: 16px;
        border: 1px solid rgba(223, 225, 230, 0.5);
        background-color: #FAFAFA;
        border-radius: 4px;
        animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);

        &::after {
            content: '';
            position: absolute;
            display: none;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 5px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            animation: checkbox-check 125ms 0ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
        }
    }

    :hover input ~ .ech-checkbox {
        background-color: #CCC;
    }

    :hover input ~ .ech-checkbox-site {
        background-color: #CCC;
    }

    input:checked ~ .ech-checkbox {
        background-color: #00CC84;
        border: 2px solid #00CC84;
    }

    input:checked ~ .ech-checkbox-site {
        background-color: $ech-site-red;
        border: 2px solid $ech-site-red;
    }

    input:checked ~ .ech-checkbox::after {
        display: block;
    }

    input:checked ~ .ech-checkbox-site::after {
        display: block;
    }
}

@keyframes shrink-bounce {

    0% {
        transform: scale(1);
    }

    33% {
        transform: scale(0.85);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes checkbox-check {

    0% {
        width: 0;
        height: 0;
        border-color: white;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    33% {
        width: 5px;
        height: 0;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    100% {
        width: 5px;
        height: 12px;
        border-color: white;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}
</style>
