import { render, staticRenderFns } from "./circle-loading.vue?vue&type=template&id=0dd59313&functional=true&"
var script = {}
import style0 from "./circle-loading.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports