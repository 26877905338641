<template>
    <div @click="$refs.file.click()" ref="zone"
         @dragover.prevent="onDragOver"
         @dragend.prevent="onDragEnd"
         @dragleave.prevent="onDragEnd"
         @drag.prevent="noop"
         @drop.prevent="onDrop"
         class="input-file"
    >
        <input ref="file" type="file" v-show="false" @change="onFileChange" :accept="accept">
        <slot :file="value" :read-result="readResult" />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        value: {
            type: process.browser ? [File, String, Object] : [String, Object],
            default: null,
        },
        imageReadable: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
            default: '*/*',
        },
        minWidth: {
            type: Number,
            default: 600,
        },
        minHeight: {
            type: Number,
            default: 600,
        },
        mimeTypes: {
            type: Array,
            default: () => {
                return ['image/png', 'image/jpeg', 'image/jpg'];
            },
        },
    },

    data () {
        return {
            readResult: null,
        };
    },

    watch: {
        value: {
            immediate: true,
            handler (t) {
                if (!t && this.$refs.file) this.$refs.file.value = '';

                if (t && this.imageReadable) {
                    if (Object.prototype.toString.call(t) === '[object File]') {
                        this.read().then(r => {
                            this.readResult = r;
                        }).catch(() => {
                            this.readResult = null;
                        });
                    } else {
                        this.readResult = t;
                    }
                } else {
                    this.readResult = null;
                }
            },
        },
    },
    methods: {
        ...mapActions(['showAlert']),

        onFileChange () {
            const { files } = this.$refs.file;
            if (files.length) {
                if (!files[0].type || !this.mimeTypes.includes(files[0].type)) return this.showAlert('Oops, File is not allowed');
                if (files[0].size > 4000000) return this.showAlert('Oops, File is large');
                if (files[0].name.length > 49) return this.showAlert('Oops, File name is long, rename');
                this.$emit('input', files ? files[0] : null);
            }
        },

        noop () {},

        onDragOver () {
            this.$refs.zone.classList.add('dragover');
            this.$emit('update:drag', true);
        },

        onDragEnd () {
            this.$refs.zone.classList.remove('dragover');
            this.$emit('update:drag', false);
        },

        onDrop (e) {
            const { files } = e.dataTransfer;
            if (!files[0].type || !this.mimeTypes.includes(files[0].type)) return this.showAlert('Oops, File is not allowed');
            if (files[0].size > 4000000) return this.showAlert('Oops, File is large');
            if (files[0].name.length > 49) return this.showAlert('Oops, File name is long, rename');

            files && files.length && this.$emit('input', files[0]);
            this.onDragEnd();
        },

        read () {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                        if (this.minWidth > image.width || this.minHeight > image.height) {
                            this.showAlert(`Oops, Check your image size [${image.width}x${image.height}]`);
                            throw new Error('Error on size');
                        }
                        resolve(reader.result);
                    };
                };
                reader.onerror = reject;
                reader.readAsDataURL(this.value);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~vars';

.input-file {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;

    &.dragover:not(.no-drag-style) {
        border: 1px dashed #DDD9D9;
        background-color: #F7F7F7;
    }
}
</style>
