import get from 'lodash/get';
import { onError } from 'apollo-link-error';
import { SET_PROFILE } from '~/scripts/utils/constants/mutations/index';
import cache from './cache';

export default function ({ app, redirect, store }) {
    const errorLink = onError(({ graphQLErrors }) => {
        graphQLErrors = graphQLErrors
            ? graphQLErrors.map((e) => {
                const code = get(e, 'extensions.code', 500);
                return Object.assign({}, e, { extensions: { code } });
            })
            : [];

        if (graphQLErrors.find(e => e.extensions.code === '401')) {
            // app.$apolloHelpers.onLogout();
            app.$storage.removeState('profile');
            store.commit(SET_PROFILE, {
                givenName: '',
                familyName: '',
            });
            // TODO: redirect('/login');
        }
    });
    return {
        httpEndpoint: process.env.API_URL,
        httpLinkOptions: {
            mode: 'no-cors',
            headers: {
                Host: process.env.API_URL_HOST,
                Origin: process.env.API_URL_BASE,
                Referer: process.env.API_URL_BASE,
            },
        },
        cache,
        link: errorLink,
    };
}
