<template>
    <div class="form-group relative"
         :class="{ 'has-error': error }"
         :data-test-id="dataTestId"
    >
        <div class="ech-label-area" v-if="inputLabel">
            <label class="label">
                {{ inputLabel }}
            </label>
        </div>
        <multi-select
            :value="$attrs['current-value'] || value"
            :options="options"
            :searchable="searchable"
            :close-on-select="closeOnSelect"
            :show-labels="showLabels"
            :placeholder="placeholder"
            :allow-empty="allowEmpty"
            :multiple="multiple"
            :custom-label="setCustomLabel"
            :loading="isLoading"
            :disabled="disabled"
            @input="update"
            @search-change="searchChange"
            @select="select"
        >
            <template slot="singleLabel"
                      slot-scope="{ option }"
            >
                <slot name="single" :option="option" />
            </template>
            <template v-if="multiple"
                      slot="tag"
            >
                {{ }}
            </template>
            <template v-if="multiple"
                      slot="selection"
                      slot-scope="{ values, isOpen }"
            >
                <span v-if="values.length && !isOpen"
                      class="multiple-placeholder"
                >
                    {{ placeholder }}
                </span>
            </template>
            <template v-if="customCaret"
                      slot="caret"
            >
                <div>
                    <div @mousedown.prevent.stop="toggle()" class="custom-caret">
                        <component :is="customCaret" />
                    </div>
                </div>
            </template>
        </multi-select>
        <template v-if="multiple && !error">
            <div class="tags-container">
                <div v-for="(item, key) in value"
                     :key="key"
                     @click="removeTag(key)"
                >
                    <div class="tag-container d-flex pointable" v-if="isString(item)">
                        {{ item }}
                        <delete-icon />
                    </div>

                    <div class="tag-container d-flex pointable" v-if="isObject(item)">
                        {{ item.name }}
                        <delete-icon />
                    </div>
                </div>
            </div>
        </template>

        <span v-show="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>
import { isString, isObject } from 'lodash';
import MultiSelect from 'vue-multiselect';
import deleteIcon from '~icons/delete';
import searchIcon from '~icons/magnifying-glass';

export default {

    components: {
        MultiSelect,
        deleteIcon,
        searchIcon,
    },

    props: {
        dataTestId: {
            type: String,
            default: 'inputMultiselect',
        },
        inputLabel: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => ([]),
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        showLabels: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Pick a value',
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: '',
        },
        boundMultiselect: {
            type: String,
            default: '',
        },
        customCaret: {
            type: String,
            default: '',
        },
        setCustomLabel: {
            type: Function,
            default: (value) => value,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Object, Array, Number, Boolean],
            default: null,
        },
        reduce: {
            type: Function,
            default (data) {
                return data;
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        isObject (value) {
            return isObject(value);
        },
        isString (value) {
            return isString(value);
        },
        searchChange (query) {
            this.$emit('searchChange', query);
        },
        update (data) {
            this.$emit('update:current-value', data);
            this.$emit('input', this.reduce(data));
        },
        select (data) {
            if (this.boundMultiselect) {
                this.$emit('select', data, this.boundMultiselect);
            }
        },
        removeTag (index) {
            const v = this.value.slice();
            v.splice(index, 1);
            this.$emit('input', v);
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

@import 'vue-multiselect/dist/vue-multiselect.min.css';

.form-group {

    &.has-error {

        .multiselect {

            .multiselect__tags {
                border-color: red;
            }
        }
    }
}

.multiselect {

    &--above {

        > .multiselect__content-wrapper {
            margin-bottom: 4px;
            border-radius: 4px;
            border-bottom: 1px solid $divider;
        }
    }

    &--active {

        .multiselect__tags {
            border-color: $general-heading;
        }
    }

    &__tags {
        border-radius: 4px !important;
        box-shadow: 0 2px 5px 0 rgba(171, 170, 170, 0.12);
        border-color: $input-border;
        height: calc(1.5em + 0.75rem + 2px);

        .multiple-placeholder {
            padding-left: 5px;
            color: #1C1717;
            opacity: 0.35;
        }
    }

    &__content {
        padding: 8px;

        &-wrapper {
            margin-top: 4px;
            border-top: 1px solid $divider;
            border-radius: 4px;
            box-shadow: 0 4px 12px 0 rgba(223, 223, 223, 0.72);
        }
    }

    &__input {
        font-size: 14px;
    }

    &__single, &__element {
        font-size: 14px;
    }

    &__option {
        color: $general-heading;

        &--highlight {
            color: #FF5F6D;
            background-color: $brand2-25;
        }
    }

    &__select {

        &::before {
            border-width: 4px 3px 0;
        }
    }

    &__placeholder {
        padding-top: 0;
        padding-left: 5px;
    }

    .custom-caret {
        position: absolute;
        width: 40px;
        height: 38px;
        right: 1px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
    }
}

.tags-container {
    display: flex;
    margin-top: 22px;
    flex-wrap: wrap;

    .tag-container {
        border-radius: 4px;
        background-color: $green-100;
        color: $white;
        font-size: 14px;
        font-family: 'CircularStd', sans-serif;
        height: 34px;
        padding: 8px;
        margin-right: 8px;
        align-items: center;
        margin-bottom: 8px;
    }
}
</style>
