import debounce from 'lodash/debounce';
import isMobile from '~/scripts/utils/detectmobilebrowser';

export default function device ({ req, app }, inject) {
    // Use user-agent for feature detection
    // So we can optimize server side rendering according to the exact device
    const ua = req ? req.headers['user-agent'] : navigator.userAgent;

    const device = {
        apple: /iP(ad|od|hone)/ig.test(ua),
        android: /Android/ig.test(ua),
        tablet: /Android|webOS|iPad|BlackBerry|IEMobile|Opera Mini/ig.test(ua),
        mobile: isMobile(ua),
        isSafari: /Version\/[0-9.](.*)Safari\/[0-9.]+/.test(ua),
    };

    device.type = device.mobile ? 'mobile' : (device.tablet ? 'tablet' : 'desktop');
    device.desktop = !(device.mobile || device.tablet);

    if (process.browser) {
        const deviceWidths = {
            0: 'mobile',
            768: 'tablet',
            992: 'desktop',
        };

        const checkDevice = debounce(function () {
            const windowWidth = window.innerWidth;
            let guessDevice = null;
            for (const key in deviceWidths) {
                if (windowWidth >= key) guessDevice = deviceWidths[key];
            }
            ['mobile', 'tablet', 'desktop'].forEach(el => {
                device[el] = el === guessDevice;
            });
        }, 300);
        window.addEventListener('resize', checkDevice);
        checkDevice();
    }

    app.store.commit('SET_DEVICE', device);
    inject('device', device);
}
