import Vue from 'vue';
import OffCanvasComponent from '~/components/common/offcanvas';
import InputText from '~/components/common/inputs/input-text';
import InputTextAreaComponent from '~/components/common/inputs/inputTextArea';
import InputPhoneComponent from '~/components/common/inputs/inputPhone';
import InputNumberComponent from '~/components/common/inputs/inputNumber';
import InputCheckboxComponent from '~/components/common/inputs/inputCheckbox';
import InputRadioComponent from '~/components/common/inputs/inputRadio';
import InputSelectComponent from '~/components/common/inputs/inputSelect';
import InputMultiSelectComponent from '~/components/common/inputs/inputMultiselect';
import InputFile from '~/components/common/inputs/inputFile';
import UploaderCropper from '~/components/common/inputs/inputFileMultiple';
import UploaderCropperNewVer from '~/components/common/inputs/inputFileMultipleNewVersion';
import UploaderSmallDragdropComponent from '~/components/common/inputs/inputFileSmallSingle';
import EchButton from '~/components/common/ech-button';
import EchLottie from '~/components/common/ech-lottie';
import inputToggle from '~/components/common/inputs/inputToggle';
import switchToggle from '~/components/common/inputs/switch';
import CircleLoading from '~/components/common/loadings/circle-loading';
import inputEditor from '~/components/common/inputs/inputEditor';

Vue.component('OffCanvasBar', OffCanvasComponent);
Vue.component('InputText', InputText);
Vue.component('InputPhone', InputPhoneComponent);
Vue.component('InputTextArea', InputTextAreaComponent);
Vue.component('InputNumber', InputNumberComponent);
Vue.component('InputCheckbox', InputCheckboxComponent);
Vue.component('InputRadio', InputRadioComponent);
Vue.component('InputSelect', InputSelectComponent);
Vue.component('InputMultiSelect', InputMultiSelectComponent);
Vue.component('UploaderCropper', UploaderCropper);
Vue.component('UploaderCropperNewVer', UploaderCropperNewVer);
Vue.component('UploaderSmall', UploaderSmallDragdropComponent);
Vue.component('InputFile', InputFile);
Vue.component('EchButton', EchButton);
Vue.component('EchLottie', EchLottie);
Vue.component('inputToggle', inputToggle);
Vue.component('switchButton', switchToggle);
Vue.component('CircleLoading', CircleLoading);
Vue.component('inputEditor', inputEditor);

Vue.directive('onScroll', {

    inserted: function (el) {
        // Focus the element
        window.addEventListener('scroll', () => {
            const offsetY = window.pageYOffset;

            if (offsetY > 50 && !el.classList.contains('scrolling')) {
                el.classList.add('scrolling');
            }

            if (offsetY < 35 && el.classList.contains('scrolling')) {
                el.classList.remove('scrolling');
            }
        });
    },

});

Vue.directive('onScrollHeight', {
    inserted: function (el) {
        if (el.scrollHeight > document.body.offsetHeight) {
            window.addEventListener('scroll', () => {
                if (window.pageYOffset >= 96) {
                    return el.classList.add('show-panel-action-on-scroll');
                }

                if (window.pageYOffset < 96) {
                    return el.classList.remove('show-panel-action-on-scroll');
                }
            });
        }
    },
});

Vue.filter('money', value => {
    if (value === 0) return 'Free';
    if (value < 1) return `${value * 100} ¢`;
    return `${value}$`;
});
