// index.js mutations
export const SET_APPLICATION_OPTIONS = 'SET_APPLICATION_OPTIONS';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_LANG = 'SET_LANG';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const SET_ALERT = 'SET_ALERT';
export const TOGGLE_ALERT = 'TOGGLE_ALERT';
export const SET_PROFILE = 'SET_PROFILE';

// campaign.js mutations
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_UPDATE_CAMPAIGN_INFO = 'SET_UPDATE_CAMPAIGN_INFO';
export const SET_PACKAGE_INFO = 'SET_PACKAGE_INFO';
export const SET_SURVEY_QUESTIONS = 'SET_SURVEY_QUESTIONS';
