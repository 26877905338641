<template>
    <div class="form-group relative" :class="{
        'has-error' : error
    }"
    >
        <div v-if="label">
            <label class="label">{{ label }} <span v-if="!required" class="optional">Optional</span></label>
            <info-icon v-if="info" v-b-tooltip.hover.topleft title="Refer to the Settings to change the currency unit." />
        </div>
        <div class="input-number" :class="{ disabled }">
            <input
                class="form-control input cypress-input-number"
                type="number"
                v-model="number"
                :name="name"
                :placeholder="placeholder"
                :disabled="disabled"
            >
            <span class="unit">{{ unit }}</span>
            <span class="buttons">
                <span class="icon chevron" @click.prevent="increase" />
                <span class="icon chevron down" @click.prevent="decrease" />
            </span>
        </div>
        <span v-show="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        info: {
            type: String,
            default: '',
        },
        value: {
            type: [Number, String],
            default: 0,
        },
        min: {
            type: [Number, String],
            default: -1000000000000000000000,
        },
        max: {
            type: [Number, String],
            default: 1000000000000000000000,
        },
        unit: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        error: {
            type: [Object, String],
            default: '',
        },
        disabled: {
            type: Boolean,
            delete: false,
        },
        reduce: {
            type: Function,
            default (value) {
                parseInt(value);
            },
        },
    },
    data () {
        return {
            number: this.value || 0,
        };
    },

    watch: {
        number (value) {
            this.$emit('input', this.reduce(value));
        },
        value (value) {
            this.number = value;
        },
    },
    methods: {
        increase () {
            if (this.disabled) return;

            if (this.max <= this.number) {
                return;
            }

            this.number++;
        },
        decrease () {
            if (this.disabled) return;

            if (this.min >= this.number) {
                return;
            }

            this.number--;
        },
    },
};
</script>

<style lang="scss">
input[ type = 'number' ]::-webkit-inner-spin-button, input[ type = 'number' ]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-number {
    display: flex;
    align-items: center;
    border: 1px solid #CCC;
    border-radius: 5px;

    &.disabled {
        background-color: #EEEEEE;

        input, span {
            cursor: not-allowed;
        }
    }

    .input {
        width: 50%;
        border: unset;
    }
}
</style>
