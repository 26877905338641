import Vue from 'vue'
import Meta from 'vue-meta'
import { createRouter } from './router.js'
import NoSsr from './components/no-ssr.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_bootstrapvue_ecd6664e from 'nuxt_plugin_bootstrapvue_ecd6664e' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_plugin_7d2dd287 from 'nuxt_plugin_plugin_7d2dd287' // Source: ./storage/plugin.js (mode: 'all')
import nuxt_plugin_moment_fe9f23a4 from 'nuxt_plugin_moment_fe9f23a4' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_plugin_a0665d8a from 'nuxt_plugin_plugin_a0665d8a' // Source: ./log-rocket/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_546dae6e from 'nuxt_plugin_sentryserver_546dae6e' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_22978634 from 'nuxt_plugin_sentryclient_22978634' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_axios_564238aa from 'nuxt_plugin_axios_564238aa' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_apollomodule_7e3eba76 from 'nuxt_plugin_apollomodule_7e3eba76' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_image_348f70b6 from 'nuxt_plugin_image_348f70b6' // Source: ../plugins/image (mode: 'all')
import nuxt_plugin_icons_34981d38 from 'nuxt_plugin_icons_34981d38' // Source: ../plugins/icons (mode: 'all')
import nuxt_plugin_device_6f3d6d68 from 'nuxt_plugin_device_6f3d6d68' // Source: ../plugins/device (mode: 'all')
import nuxt_plugin_timeout_cf02fdaa from 'nuxt_plugin_timeout_cf02fdaa' // Source: ../plugins/timeout (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_sortable_562ad16e from 'nuxt_plugin_sortable_562ad16e' // Source: ../plugins/sortable (mode: 'client')
import nuxt_plugin_highchart_46b798c6 from 'nuxt_plugin_highchart_46b798c6' // Source: ../plugins/highchart (mode: 'client')
import nuxt_plugin_globalcomponents_91a8526c from 'nuxt_plugin_globalcomponents_91a8526c' // Source: ../plugins/global-components (mode: 'all')
import nuxt_plugin_veevalidate_6e5ad03a from 'nuxt_plugin_veevalidate_6e5ad03a' // Source: ../plugins/vee-validate (mode: 'all')
import nuxt_plugin_i18n_1fba523a from 'nuxt_plugin_i18n_1fba523a' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_cropper_24b16057 from 'nuxt_plugin_cropper_24b16057' // Source: ../plugins/cropper (mode: 'client')
import nuxt_plugin_infinitescroll_64381b20 from 'nuxt_plugin_infinitescroll_64381b20' // Source: ../plugins/infinite-scroll (mode: 'client')
import nuxt_plugin_powerbi_26431785 from 'nuxt_plugin_powerbi_26431785' // Source: ../plugins/power-bi (mode: 'client')
import nuxt_plugin_vue2editor_60d31c90 from 'nuxt_plugin_vue2editor_60d31c90' // Source: ../plugins/vue2-editor (mode: 'client')
import nuxt_plugin_codemirror_042c3a24 from 'nuxt_plugin_codemirror_042c3a24' // Source: ../plugins/code-mirror.js (mode: 'client')

// Component: <NoSsr>
Vue.component(NoSsr.name, NoSsr)

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (typeof nuxt_plugin_bootstrapvue_ecd6664e === 'function') {
    await nuxt_plugin_bootstrapvue_ecd6664e(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_7d2dd287 === 'function') {
    await nuxt_plugin_plugin_7d2dd287(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_fe9f23a4 === 'function') {
    await nuxt_plugin_moment_fe9f23a4(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_a0665d8a === 'function') {
    await nuxt_plugin_plugin_a0665d8a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_546dae6e === 'function') {
    await nuxt_plugin_sentryserver_546dae6e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_22978634 === 'function') {
    await nuxt_plugin_sentryclient_22978634(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_564238aa === 'function') {
    await nuxt_plugin_axios_564238aa(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_7e3eba76 === 'function') {
    await nuxt_plugin_apollomodule_7e3eba76(app.context, inject)
  }

  if (typeof nuxt_plugin_image_348f70b6 === 'function') {
    await nuxt_plugin_image_348f70b6(app.context, inject)
  }

  if (typeof nuxt_plugin_icons_34981d38 === 'function') {
    await nuxt_plugin_icons_34981d38(app.context, inject)
  }

  if (typeof nuxt_plugin_device_6f3d6d68 === 'function') {
    await nuxt_plugin_device_6f3d6d68(app.context, inject)
  }

  if (typeof nuxt_plugin_timeout_cf02fdaa === 'function') {
    await nuxt_plugin_timeout_cf02fdaa(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sortable_562ad16e === 'function') {
    await nuxt_plugin_sortable_562ad16e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_highchart_46b798c6 === 'function') {
    await nuxt_plugin_highchart_46b798c6(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_91a8526c === 'function') {
    await nuxt_plugin_globalcomponents_91a8526c(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_6e5ad03a === 'function') {
    await nuxt_plugin_veevalidate_6e5ad03a(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_1fba523a === 'function') {
    await nuxt_plugin_i18n_1fba523a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_cropper_24b16057 === 'function') {
    await nuxt_plugin_cropper_24b16057(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infinitescroll_64381b20 === 'function') {
    await nuxt_plugin_infinitescroll_64381b20(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_powerbi_26431785 === 'function') {
    await nuxt_plugin_powerbi_26431785(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vue2editor_60d31c90 === 'function') {
    await nuxt_plugin_vue2editor_60d31c90(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_codemirror_042c3a24 === 'function') {
    await nuxt_plugin_codemirror_042c3a24(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
