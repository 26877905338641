<template>
    <div class="image-cropper-page">
        <div class="ech-panel" style="height: 100%;">
            <div class="ech-panel-header d-flex justify-content-start align-items-center">
                <h2>
                    Finalize Your Sample Image
                </h2>
            </div>

            <div class="ech-panel-content" style="height: calc(100% - 192px - 170px);">
                <div>
                    <vueCropper
                        ref="cropper"
                        :img="imageData"
                        :output-size="option.size"
                        :output-type="option.outputType"
                        :info="true"
                        :full="option.full"
                        :can-move="option.canMove"
                        :can-move-box="option.canMoveBox"
                        :fixed-box="option.fixedBox"
                        :original="option.original"
                        :auto-crop="option.autoCrop"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :center-box="option.centerBox"
                        :high="option.high"
                        :info-true="option.infoTrue"
                        :max-img-size="option.maxImageSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                    />
                    <!-- @realTime="realTime" -->
                    <!-- @imgLoad="imgLoad" -->
                    <!-- @cropMoving="startCrap" -->
                </div>
            </div>

            <div class="ech-panel-footer">
                <div>
                    <button type="submit"
                            @click.prevent="save"
                            class="btn-action"
                            id="cropper-save"
                    >
                        Save
                    </button>

                    <button type="button"
                            class="btn-cancel"
                            id="cropper-cancel"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        imageData: {
            type: String,
            required: true,
        },
        handler: {
            type: Function,
            default: () => {},
        },
    },

    data () {
        return {
            option: {
                size: 1,
                full: false,
                outputType: 'png',
                canMove: false,
                fixed: true,
                original: false,
                canMoveBox: true,
                autoCrop: true,
                // 只有自动截图开启 宽度高度才生效
                autoCropWidth: 200,
                autoCropHeight: 150,
                centerBox: false,
                high: false,
                cropData: {},
                enlarge: 1,
                mode: 'cover',
                maxImgSize: 2000,
            },
        };
    },

    methods: {
        startCrap () {
            // this.$refs.cropper.startCrop();

        },

        save () {
            this.$refs.cropper.getCropData((data) => {
            // do something
                this.handler(data);
                this.$destroy();
                this.$el.remove();
            });
        },
    },
};
</script>
