<i18n>
{
    "en": {
        "optional": "Optional",
        "charactersLeft": "characters left"
    }
}
</i18n>

<template>
    <div class="form-group relative"
         :class="{'has-error': error, 'relative': clearInput }"
         @click="handleClick"
    >
        <div class="ech-label-area" v-if="showLabel">
            <label class="label" :for="id">
                <span>
                    {{ label }}
                    <span v-if="!required"
                          :class="optionalClassName"
                    >
                        {{ $t('optional') }}
                    </span>
                </span>

            </label>
            <span class="ech-input-length-counter"
                  v-if="lengthCounter"
            >
                <span v-if="maxLength > 0">
                    <span :class="lengthClass">{{ maxLength - length }}</span>
                    /
                    <span class="secondary-text-100">{{ maxLength }}</span>
                </span>
                {{ $t('charactersLeft') }}
            </span>
        </div>

        <input v-if="inputType === 'number'" class="form-control input"
               :id="id"
               :class="[generatedClasses, { disabled }]"
               :disabled="disabled"
               :readonly="readonly"
               ref="input"
               v-model.number="localValue"
               autocomplete="off"
               :autofocus="autofocus"
               :name="name"
               :type="inputType"
               :placeholder="placeholder"
               :maxlength="maxLength"
               :min="min"
               :data-test-id="dataTestId"
               @focus="onFocus"
        >

        <input v-else
               :id="id"
               class="form-control input"
               :class="[generatedClasses, { disabled }]"
               :disabled="disabled"
               :readonly="readonly"
               ref="input"
               v-model="localValue"
               autocomplete="off"
               :autofocus="autofocus"
               :name="name"
               :type="inputType"
               :placeholder="placeholder"
               :maxlength="maxLength"
               :data-test-id="dataTestId"
               @focus="onFocus"
        >

        <div @click="clearValue"
             class="clear-input pointable text-danger"
             v-if="clearInput"
        >
            <v-icon class="d-block" name="delete" />
        </div>

        <span v-show="error && showTextError" class="error-message">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        dataTestId: {
            type: String,
            default: () => {
                return 'input-test-cypress';
            },
        },
        label: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        optionalClassName: {
            type: String,
            default: 'ech-optional',
        },
        maxLength: {
            type: Number,
            default: 10000,
        },
        lengthCounter: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            required: false,
        },
        onFocus: {
            type: Function,
            default: () => {

            },
        },
        value: {
            type: [String, Number],
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        showTextError: {
            type: Boolean,
            default: true,
        },
        inputClasses: {
            type: String,
            default: '',
        },
        inputType: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        notSelectable: {
            type: Boolean,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        clearInput: {
            type: Boolean,
            default: false,
        },
        clearFunction: {
            type: Function,
            default: () => {},
        },
        clickHandler: {
            type: Function,
            default: () => {},
        },
    },
    data () {
        return {
            id: null,
        };
    },
    computed: {
        localValue: {
            get () {
                return this.value || (this.inputType === 'text' || this.inputType === 'password' ? '' : 0);
            },
            set (x) {
                this.$emit('input', x);
            },
        },
        lengthClass () {
            if ((this.maxLength - this.length) <= Math.floor(0.1 * this.maxLength)) {
                return 'brand-1-100';
            }
            return 'green-100';
        },
        generatedClasses () {
            return `${this.inputClasses} ${this.notSelectable ? 'not-selectable' : ''} ${this.clearInput ? 'iconic' : ''}`;
        },
        length () {
            return (this.value || this.localValue).length;
        },
    },
    watch: {
        value (value) {
            this.localValue = value;
        },
    },
    mounted () {
        this.id = 'input-' + this._uid;
    },
    methods: {
        clearValue () {
            this.$emit('input', '');
            this.$emit('clear');
        },
        handleClick () {
            this.clickHandler();
        },
    },
};
</script>

<style lang="scss">
@import '~vars';

.clear-input {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -8px;

    svg {

        g, use {
            fill: $general-red;
        }
    }
}

.input {

    &.disabled {
        cursor: not-allowed;
        background-color: #FFFCFC !important;
        border-color: #CFCACA;
    }
}
</style>
