import get from 'lodash/get';
import { SET_PROFILE } from '~/scripts/utils/constants/mutations/index';
import profileGql from '~/gql/queries/profile.gql';

const allowedRoutes = [
    '/logout',
    '/profile',
    '/settings',
    '/contact',
];

export default async function ({ app, redirect, route, store, from }) {
    const hasToken = app.$apolloHelpers.getToken();
    if (hasToken) {
        try {
            let profile = get(store.state, 'profile');

            if (!profile.email) {
                const apollo = app.apolloProvider.defaultClient;
                const response = await apollo.query({
                    query: profileGql,
                });

                profile = response.data.profile.result;
            }

            if (allowedRoutes.includes(route.path)) return;
            // add exception when coming from login page
            if (from && from.name === 'login') return;

            const path = profile.roles.toLowerCase();

            const isSampler = /sampler/gi.test(path);
            const isSA = /SystemAdministrator/gi.test(path);
            const isACM = /AccountManager/gi.test(path);
            store.commit(SET_PROFILE, {
                ...profile,
                isSampler,
                isSA,
                isACM,
            });
            if (!route.path.includes(`/${path}/`)) {
                if (process.client) {
                    setTimeout(() => {
                        redirect(`/${path}`);
                    }, 50);
                } else {
                    return redirect(`/${path}`);
                }
            }
        } catch (e) {}
    }
}
