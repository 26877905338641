const middleware = {}

middleware['i18n'] = require('../middleware/i18n.js');
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['isAuth'] = require('../middleware/isAuth.js');
middleware['isAuth'] = middleware['isAuth'].default || middleware['isAuth']

middleware['roleCheckRedirection'] = require('../middleware/roleCheckRedirection.js');
middleware['roleCheckRedirection'] = middleware['roleCheckRedirection'].default || middleware['roleCheckRedirection']

export default middleware
