import {
    SET_APPLICATION_OPTIONS,
    SET_DEVICE,
    SET_LANG,
    SET_PAGE_TITLE,
    TOGGLE_ALERT,
    SET_ALERT,
    SET_PROFILE,
} from '~/scripts/utils/constants/mutations/index';
import dashboardApplicationOptionsGql from '~/gql/queries/dashboardApplicationOptions.gql';

export const strict = false;

export const state = () => ({
    applicationOptions: null,
    device: {},
    showAlert: false,
    alertContent: {
        text: 'An Error Has Occurred.',
        type: 'danger',
        duration: 5000,
    },
    locales: ['en', 'es'],
    locale: 'en',
    pageTitle: '',
    sideMenu: 'EchSideMenu',
    sideMenuData: {
        collapse: true,
        checkedByState: false,
        back: false,
        backLink: '',
        items: [],
        callback: null,
    },
    profile: {
        givenName: '',
        familyName: '',
    },
});

export const mutations = {
    [SET_APPLICATION_OPTIONS] (state, options) {
        state.applicationOptions = options;
    },
    [SET_DEVICE] (state, device) {
        state.device = Object.assign(state.device, device);
    },
    [TOGGLE_ALERT] (state, payload) {
        state.showAlert = payload;
    },
    [SET_ALERT] (state, payload) {
        state.alertContent = Object.assign(state.alertContent, payload);
    },
    [SET_LANG] (state, locale) {
        if (state.locales.indexOf(locale) !== -1) {
            state.locale = locale;
        }
    },
    [SET_PAGE_TITLE] (state, title) {
        state.pageTitle = title;
    },
    setSideMenu (state, sideMenuComponent) {
        state.sideMenu = sideMenuComponent;
    },
    setSideMenuData (state, input) {
        // Object.assign(state.sideMenuData, input);
        state.sideMenuData = input;
    },
    [SET_PROFILE] (state, profile) {
        state.profile = profile;
    },
};

export const actions = {
    async nuxtServerInit ({ commit, dispatch }, { app }) {
        const apollo = app.apolloProvider.defaultClient;
        if (process.APPLICATION_OPTIONS) {
            commit(SET_APPLICATION_OPTIONS, process.APPLICATION_OPTIONS);
        } else {
            const result = await apollo.query({
                query: dashboardApplicationOptionsGql,
            });
            const options = result.data.dashboardApplicationOptions.result;
            process.APPLICATION_OPTIONS = options;
            commit(SET_APPLICATION_OPTIONS, options);
        }
    },
    showAlert ({ state, commit }, payload) {
        if (typeof payload === 'string') {
            payload = { text: payload };
        }
        payload = Object.assign({
            ...state.alertContent,
        }, payload);
        commit(SET_ALERT, payload);
        commit(TOGGLE_ALERT, true);
    },
};
