import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _57ad98b1 = () => interopDefault(import('../pages/accountmanager/index.vue' /* webpackChunkName: "pages/accountmanager/index" */))
const _2fdbf976 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _3ba3611a = () => interopDefault(import('../pages/demo-request.vue' /* webpackChunkName: "pages/demo-request" */))
const _35cb0f8b = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1e363afa = () => interopDefault(import('../pages/marketer/index.vue' /* webpackChunkName: "pages/marketer/index" */))
const _b5da5ce4 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _90924d1e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _0b1a81a9 = () => interopDefault(import('../pages/restricted.vue' /* webpackChunkName: "pages/restricted" */))
const _7cd074b4 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _b05690ba = () => interopDefault(import('../pages/systemadministrator/index.vue' /* webpackChunkName: "pages/systemadministrator/index" */))
const _9e616472 = () => interopDefault(import('../pages/verify.vue' /* webpackChunkName: "pages/verify" */))
const _c03e6884 = () => interopDefault(import('../pages/widgetmarketer/index.vue' /* webpackChunkName: "pages/widgetmarketer/index" */))
const _728e79c0 = () => interopDefault(import('../pages/accountmanager/brands/index.vue' /* webpackChunkName: "pages/accountmanager/brands/index" */))
const _3444fcbd = () => interopDefault(import('../pages/accountmanager/companies/index.vue' /* webpackChunkName: "pages/accountmanager/companies/index" */))
const _05041fcc = () => interopDefault(import('../pages/accountmanager/marketers/index.vue' /* webpackChunkName: "pages/accountmanager/marketers/index" */))
const _669b12a2 = () => interopDefault(import('../pages/accountmanager/mixin.js' /* webpackChunkName: "pages/accountmanager/mixin" */))
const _231e83cb = () => interopDefault(import('../pages/accountmanager/offers/index.vue' /* webpackChunkName: "pages/accountmanager/offers/index" */))
const _8b86d53e = () => interopDefault(import('../pages/accountmanager/reports/index.vue' /* webpackChunkName: "pages/accountmanager/reports/index" */))
const _73b0e1ce = () => interopDefault(import('../pages/accountmanager/survey/index.vue' /* webpackChunkName: "pages/accountmanager/survey/index" */))
const _821b0fda = () => interopDefault(import('../pages/accountmanager/targeting/index.vue' /* webpackChunkName: "pages/accountmanager/targeting/index" */))
const _6021f216 = () => interopDefault(import('../pages/marketer/base.js' /* webpackChunkName: "pages/marketer/base" */))
const _dd9d253c = () => interopDefault(import('../pages/marketer/offers/index.vue' /* webpackChunkName: "pages/marketer/offers/index" */))
const _0e86deaa = () => interopDefault(import('../pages/marketer/reports/index.vue' /* webpackChunkName: "pages/marketer/reports/index" */))
const _8ab6d8fa = () => interopDefault(import('../pages/password/forget.vue' /* webpackChunkName: "pages/password/forget" */))
const _3da82b29 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _4fb1fd8f = () => interopDefault(import('../pages/systemadministrator/3rd-party-services/index.vue' /* webpackChunkName: "pages/systemadministrator/3rd-party-services/index" */))
const _1b59be9f = () => interopDefault(import('../pages/systemadministrator/acm/index.vue' /* webpackChunkName: "pages/systemadministrator/acm/index" */))
const _62dbe54a = () => interopDefault(import('../pages/systemadministrator/banners/index.vue' /* webpackChunkName: "pages/systemadministrator/banners/index" */))
const _5f47ff20 = () => interopDefault(import('../pages/systemadministrator/fail-to-ban/index.vue' /* webpackChunkName: "pages/systemadministrator/fail-to-ban/index" */))
const _3f361099 = () => interopDefault(import('../pages/systemadministrator/offers/index.vue' /* webpackChunkName: "pages/systemadministrator/offers/index" */))
const _b6287eba = () => interopDefault(import('../pages/systemadministrator/profiling-survey/index.vue' /* webpackChunkName: "pages/systemadministrator/profiling-survey/index" */))
const _c7c6f95c = () => interopDefault(import('../pages/systemadministrator/push-notification/index.vue' /* webpackChunkName: "pages/systemadministrator/push-notification/index" */))
const _362e13ad = () => interopDefault(import('../pages/systemadministrator/push-notification-v2/index.vue' /* webpackChunkName: "pages/systemadministrator/push-notification-v2/index" */))
const _7cab22b0 = () => interopDefault(import('../pages/systemadministrator/push-sms/index.vue' /* webpackChunkName: "pages/systemadministrator/push-sms/index" */))
const _9e08a46a = () => interopDefault(import('../pages/systemadministrator/rules/index.vue' /* webpackChunkName: "pages/systemadministrator/rules/index" */))
const _25a8d223 = () => interopDefault(import('../pages/systemadministrator/suspicion/index.vue' /* webpackChunkName: "pages/systemadministrator/suspicion/index" */))
const _f7045644 = () => interopDefault(import('../pages/systemadministrator/tags-and-marks/index.vue' /* webpackChunkName: "pages/systemadministrator/tags-and-marks/index" */))
const _3efc4399 = () => interopDefault(import('../pages/systemadministrator/user-management/index.vue' /* webpackChunkName: "pages/systemadministrator/user-management/index" */))
const _d7643e56 = () => interopDefault(import('../pages/systemadministrator/users-marks/index.vue' /* webpackChunkName: "pages/systemadministrator/users-marks/index" */))
const _00c09c5e = () => interopDefault(import('../pages/widgetmarketer/widgets/index.vue' /* webpackChunkName: "pages/widgetmarketer/widgets/index" */))
const _d8af0bbe = () => interopDefault(import('../pages/accountmanager/offers/edit.vue' /* webpackChunkName: "pages/accountmanager/offers/edit" */))
const _869eecc8 = () => interopDefault(import('../pages/marketer/offers/create.vue' /* webpackChunkName: "pages/marketer/offers/create" */))
const _8a3e4b9e = () => interopDefault(import('../pages/systemadministrator/offers/offer-table-row.vue' /* webpackChunkName: "pages/systemadministrator/offers/offer-table-row" */))
const _20409e7b = () => interopDefault(import('../pages/systemadministrator/profiling-survey/create.vue' /* webpackChunkName: "pages/systemadministrator/profiling-survey/create" */))
const _3f073a20 = () => interopDefault(import('../pages/widgetmarketer/widgets/create.vue' /* webpackChunkName: "pages/widgetmarketer/widgets/create" */))
const _52b318f5 = () => interopDefault(import('../pages/systemadministrator/settings/website/faq.vue' /* webpackChunkName: "pages/systemadministrator/settings/website/faq" */))
const _6c236c74 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
            return { x: 0, y: 0 };
        }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/accountmanager",
      component: _57ad98b1,
      name: "accountmanager"
    }, {
      path: "/contact",
      component: _2fdbf976,
      name: "contact"
    }, {
      path: "/demo-request",
      component: _3ba3611a,
      name: "demo-request"
    }, {
      path: "/login",
      component: _35cb0f8b,
      name: "login"
    }, {
      path: "/marketer",
      component: _1e363afa,
      name: "marketer"
    }, {
      path: "/profile",
      component: _b5da5ce4,
      name: "profile"
    }, {
      path: "/register",
      component: _90924d1e,
      name: "register"
    }, {
      path: "/restricted",
      component: _0b1a81a9,
      name: "restricted"
    }, {
      path: "/settings",
      component: _7cd074b4,
      name: "settings"
    }, {
      path: "/systemadministrator",
      component: _b05690ba,
      name: "systemadministrator"
    }, {
      path: "/verify",
      component: _9e616472,
      name: "verify"
    }, {
      path: "/widgetmarketer",
      component: _c03e6884,
      name: "widgetmarketer"
    }, {
      path: "/accountmanager/brands",
      component: _728e79c0,
      name: "accountmanager-brands"
    }, {
      path: "/accountmanager/companies",
      component: _3444fcbd,
      name: "accountmanager-companies"
    }, {
      path: "/accountmanager/marketers",
      component: _05041fcc,
      name: "accountmanager-marketers"
    }, {
      path: "/accountmanager/mixin",
      component: _669b12a2,
      name: "accountmanager-mixin"
    }, {
      path: "/accountmanager/offers",
      component: _231e83cb,
      name: "accountmanager-offers"
    }, {
      path: "/accountmanager/reports",
      component: _8b86d53e,
      name: "accountmanager-reports"
    }, {
      path: "/accountmanager/survey",
      component: _73b0e1ce,
      name: "accountmanager-survey"
    }, {
      path: "/accountmanager/targeting",
      component: _821b0fda,
      name: "accountmanager-targeting"
    }, {
      path: "/marketer/base",
      component: _6021f216,
      name: "marketer-base"
    }, {
      path: "/marketer/offers",
      component: _dd9d253c,
      name: "marketer-offers"
    }, {
      path: "/marketer/reports",
      component: _0e86deaa,
      name: "marketer-reports"
    }, {
      path: "/password/forget",
      component: _8ab6d8fa,
      name: "password-forget"
    }, {
      path: "/password/reset",
      component: _3da82b29,
      name: "password-reset"
    }, {
      path: "/systemadministrator/3rd-party-services",
      component: _4fb1fd8f,
      name: "systemadministrator-3rd-party-services"
    }, {
      path: "/systemadministrator/acm",
      component: _1b59be9f,
      name: "systemadministrator-acm"
    }, {
      path: "/systemadministrator/banners",
      component: _62dbe54a,
      name: "systemadministrator-banners"
    }, {
      path: "/systemadministrator/fail-to-ban",
      component: _5f47ff20,
      name: "systemadministrator-fail-to-ban"
    }, {
      path: "/systemadministrator/offers",
      component: _3f361099,
      name: "systemadministrator-offers"
    }, {
      path: "/systemadministrator/profiling-survey",
      component: _b6287eba,
      name: "systemadministrator-profiling-survey"
    }, {
      path: "/systemadministrator/push-notification",
      component: _c7c6f95c,
      name: "systemadministrator-push-notification"
    }, {
      path: "/systemadministrator/push-notification-v2",
      component: _362e13ad,
      name: "systemadministrator-push-notification-v2"
    }, {
      path: "/systemadministrator/push-sms",
      component: _7cab22b0,
      name: "systemadministrator-push-sms"
    }, {
      path: "/systemadministrator/rules",
      component: _9e08a46a,
      name: "systemadministrator-rules"
    }, {
      path: "/systemadministrator/suspicion",
      component: _25a8d223,
      name: "systemadministrator-suspicion"
    }, {
      path: "/systemadministrator/tags-and-marks",
      component: _f7045644,
      name: "systemadministrator-tags-and-marks"
    }, {
      path: "/systemadministrator/user-management",
      component: _3efc4399,
      name: "systemadministrator-user-management"
    }, {
      path: "/systemadministrator/users-marks",
      component: _d7643e56,
      name: "systemadministrator-users-marks"
    }, {
      path: "/widgetmarketer/widgets",
      component: _00c09c5e,
      name: "widgetmarketer-widgets"
    }, {
      path: "/accountmanager/offers/edit",
      component: _d8af0bbe,
      name: "accountmanager-offers-edit"
    }, {
      path: "/marketer/offers/create",
      component: _869eecc8,
      name: "marketer-offers-create"
    }, {
      path: "/systemadministrator/offers/offer-table-row",
      component: _8a3e4b9e,
      name: "systemadministrator-offers-offer-table-row"
    }, {
      path: "/systemadministrator/profiling-survey/create",
      component: _20409e7b,
      name: "systemadministrator-profiling-survey-create"
    }, {
      path: "/widgetmarketer/widgets/create",
      component: _3f073a20,
      name: "widgetmarketer-widgets-create"
    }, {
      path: "/systemadministrator/settings/website/faq",
      component: _52b318f5,
      name: "systemadministrator-settings-website-faq"
    }, {
      path: "/",
      component: _6c236c74,
      name: "index"
    }],

    fallback: false
  })
}
