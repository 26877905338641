<template>
    <div>
        <ech-side-menu />
        <div class="main-wrapper">
            <ech-header :page-title="pageTitle" />
            <div class="main-container"
                 :class="{ 'pt-0': $route.path === '/offers/create' }"
            >
                <nuxt :page-title="pageTitle" />
            </div>
        </div>
        <ech-alert />
    </div>
</template>

<script>
import EchSideMenu from '~/components/layout/ech-side-menu';
import EchHeader from '~/components/layout/ech-header';
import EchAlert from '~/components/common/ech-alert';

export default {
    components: {
        EchAlert,
        EchHeader,
        EchSideMenu,
    },
    middleware: ['roleCheckRedirection'],
    data () {
        return {
            pageTitle: 'Dashboard',
        };
    },
    head () {
        return {
            htmlAttrs: {
                class: [
                    this.$store.state.device.apple && 'apple-device',
                ].filter(Boolean).join(' '),
            },
            bodyAttrs: {
                class: [
                    this.$store.state.device.android && 'android-device',
                    this.$store.state.device.apple && 'apple-device',
                    this.$store.state.device.tablet && 'tablet-device',
                    this.$store.state.device.mobile && 'mobile',
                    this.$store.state.overflow && 'overflow-hidden',
                ].filter(Boolean).join(' '),
            },
        };
    },
};
</script>
