<template>
    <div ref="animation"
         v-if="shouldDisplay"
    />
</template>

<script>
import lottie from 'lottie-web';
import loadingAnimation from '~/lottie/loading.json';
import loadingDisabledAnimation from '~/lottie/loading-disabled.json';

export default {
    props: {
        shouldDisplay: {
            type: Boolean,
            default: false,
        },
        animationName: {
            type: String,
            default: 'loadingAnimation',
        },
    },
    mounted () {
        if (this.shouldDisplay) {
            let animationData;
            switch (this.animationName) {
            case 'loadingAnimation':
                animationData = loadingAnimation;
                break;
            case 'loadingDisabledAnimation':
                animationData = loadingDisabledAnimation;
                break;
            }
            lottie.loadAnimation({
                container: this.$refs.animation,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData,
            });
        }
    },
};
</script>
