<i18n>
{
    "en": {
        "companyName": "Company Name"
    }
}
</i18n>

<template>
    <div class="sidebar">
        <nuxt-link class="logo-link" to="/" v-if="!sideMenuData.back">
            <div class="logo-container">
                <div class="logo">
                    <img :src="$image('logos/peekage.png')">
                </div>
                <strong class="peekage-company-title">
                    {{ $t('appName') }}
                </strong>
            </div>
        </nuxt-link>

        <div class="back" v-if="sideMenuData.back">
            <nuxt-link class="btn-back" :to="sideMenuData.backLink">
                <button class="btn btn-circle mr-16" id="sidemenu-back">
                    <span class="icon white arrow-left align-middle" />
                </button>
                <span class="text medium bold">Main Menu</span>
            </nuxt-link>
        </div>

        <div class="company">
            <span class="company-title">
                {{ isSA ? $t('appName') : $t('companyName') }}
            </span>
            <span class="company-title-short">CN</span>
            <span v-if="sideMenuData.collapse" class="side-bar-toggler" @click="sidebarToggle" />
        </div>
        <div class="d-flex flex-column flex-grow-1 overflow-auto">
            <ul class="menus align-self-start">
                <echSideMenuItem
                    :key="index" v-for="(menu, index) in sideMenuData.items"
                    :menu="menu"
                    :callback="sideMenuData.callback"
                    :index="menu.index"
                    :current="menu.state.current"
                    :disabled="menu.state.disabled"
                    :passed="menu.state.passed"
                    :checked-by-state="sideMenuData.checkedByState"
                />
            </ul>

            <!-- <ul class="menus extra-menu pb-3">
                <li class="menu">
                    <nuxt-link to="/contact" class="item">
                        <span class="icon paper-plane white" />
                        <span class="text">
                            Contact Us
                        </span>
                    </nuxt-link>
                </li>
                <li class="menu">
                    <nuxt-link to="/settings" class="item">
                        <span class="icon cog white" />
                        <span class="text">
                            Settings
                        </span>
                    </nuxt-link>
                </li>
            </ul> -->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash/get';
import echSideMenuItem from './ech-side-menu-item';

export default {
    components: {
        echSideMenuItem,
    },
    computed: {
        ...mapState(['sideMenuData', 'profile']),

        isSA () {
            return get(this.profile, 'isSA');
        },
    },
    watch: {
        'sideMenuData.collapse' (collapse) {
            if (!collapse) {
                const mainWrapper = document.querySelector('.main-wrapper');

                mainWrapper.classList.remove('collapsed');
                return this.$el.classList.remove('collapsed');
            }
        },
    },
    methods: {
        sidebarToggle () {
            const mainWrapper = document.querySelector('.main-wrapper');

            if (this.$el.classList.contains('collapsed')) {
                mainWrapper.classList.remove('collapsed');
                return this.$el.classList.remove('collapsed');
            }

            mainWrapper.classList.add('collapsed');
            return this.$el.classList.add('collapsed');
        },
    },
};
</script>
