export default function ({ app, redirect }) {
    // check if a token has been saved for the user
    const hasToken = !!app.$apolloHelpers.getToken();
    if (!hasToken) {
        // redirect the user to our 404 page
        // error({ statusCode: 404, message: 'You are not allowed to see this' });
        // redirect to login page
        redirect('/login');
    }
}
