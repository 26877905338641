import Vue from 'vue';
import Icon from 'vue-awesome/components/Icon';

Vue.component('v-icon', Icon);

Icon.register({
    add: {
        width: 16,
        height: 16,
        d: 'M7.074 7.074H3.26a.925.925 0 1 0 0 1.852h3.815v3.815a.925.925 0 1 0 1.852 0V8.926h3.815a.925.925 0 1 0 0-1.852H8.926V3.26a.925.925 0 1 0-1.852 0v3.815z',
    },
    play: {
        width: 8,
        height: 10,
        d: 'M0 1v8a1 1 0 0 0 1.6.8l5.333-4a1 1 0 0 0 0-1.6L1.6.2A1 1 0 0 0 0 1z',
    },
    delete: {
        width: 16,
        height: 16,
        d: 'M8 6.647l2.37-2.369a.953.953 0 0 1 1.348.003.952.952 0 0 1 .003 1.35L9.352 7.998l2.37 2.37a.952.952 0 0 1-.004 1.348.953.953 0 0 1-1.349.003L8 9.351l-2.37 2.37a.953.953 0 0 1-1.348-.004.952.952 0 0 1-.003-1.349L6.648 8 4.278 5.63a.952.952 0 0 1 .004-1.349.953.953 0 0 1 1.349-.003L8 6.648z',
    },
    attachImage: {
        width: 24,
        height: 24,
        d: 'M13.416 4a5.001 5.001 0 1 1 5.784 6.855v9.508c0 .904-.723 1.637-1.616 1.637H4.616A1.627 1.627 0 0 1 3 20.363V5.637C3 4.733 3.723 4 4.616 4h8.8zm-.412 1.8H4.8l.004 14.4H17.4l-.002-9.236A5 5 0 0 1 13.004 5.8zm1.463 9.2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-6.536-1.028c.228-.505.687-.56 1.023-.126l1.441 1.861a1.03 1.03 0 0 0 1.419.2l.289-.21a.92.92 0 0 1 1.338.255l1.387 2.203c.294.467.084.845-.469.845H6.671c-.553 0-.817-.408-.589-.916l1.85-4.112zM18.857 3.86a.858.858 0 1 0-1.714 0v1.283H15.86a.858.858 0 1 0 0 1.714h1.283V8.14a.858.858 0 1 0 1.714 0V6.857h1.283a.858.858 0 1 0 0-1.714h-1.283V3.86z',
    },
    removeImage: {
        width: 24,
        height: 24,
        d: 'M13.416 4a5.001 5.001 0 1 1 5.784 6.855v9.508c0 .904-.723 1.637-1.616 1.637H4.616A1.627 1.627 0 0 1 3 20.363V5.637C3 4.733 3.723 4 4.616 4h8.8zm-.412 1.8H4.8l.004 14.4H17.4l-.002-9.236A5 5 0 0 1 13.004 5.8zm1.463 9.2a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm-6.536-1.028c.228-.505.687-.56 1.023-.126l1.441 1.861a1.03 1.03 0 0 0 1.419.2l.289-.21a.92.92 0 0 1 1.338.255l1.387 2.203c.294.467.084.845-.469.845H6.671c-.553 0-.817-.408-.589-.916l1.85-4.112zm7.929-8.83a.858.858 0 1 0 0 1.715h4.28a.858.858 0 1 0 0-1.714h-4.28z',
    },
    save: {
        width: 24,
        height: 24,
        d: 'M15 9.003c0 .55-.446.997-.998.997H6.998A.998.998 0 0 1 6 9.003V3.999H3.996v16H6v-5.004c0-.55.449-.995 1.002-.995h9.996c.553 0 1.002.45 1.002.995V20h1.996l.021-12.154L16.174 4H15v5.003zm-5-5.004V8h3V4h-3zM4 21.997c-1.105 0-2-.899-2-2v-16a2 2 0 0 1 2.001-2l12.5.003c.276 0 .654.155.85.352l4.297 4.296c.195.195.352.576.352.851v12.497c0 1.105-.898 2-2 2H4zM16 16H8v3.999h8V16z',
    },
    uploadDoc: {
        width: 24,
        height: 24,
        paths: [
            {
                d: 'M3.495 2.5l.005 16h4.426A7.488 7.488 0 0 1 7.5 16a7.497 7.497 0 0 1 3.756-6.5H9.5a1 1 0 0 1-1-1v-6H3.495zm12.005 5h-5v-5l5 5zm.5 6.004a1 1 0 1 0-2 0v2.581l-.793-.792a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l2.5-2.5a1 1 0 0 0-1.414-1.414l-.793.82v-2.609zM17.5 7.2c0-.271-.157-.656-.351-.85L11.653.85a1.388 1.388 0 0 0-.85-.351H3.495a2 2 0 0 0-1.995 2v16c0 1.102.896 2 2.002 2H9a7.5 7.5 0 1 0 8.5-11.573l.001-1.728zm3 8.801a5.5 5.5 0 1 0-11 0 5.5 5.5 0 0 0 11 0z',
                'fill-rule': 'evenodd',
            },
        ],
    },
    marketer: {
        width: 24,
        height: 24,
        d: 'M10.784 8.5h2.435l.498-1.993-3.426-.007.493 2zm10.345-6.98a1 1 0 0 1 .88 1.106c-.265 2.34-2.08 3.448-4.831 3.77-.47.056-.887.086-1.396.1l-.398 1.586a7.502 7.502 0 0 0-2.294 14.1l-.29.218c-.442.331-1.155.334-1.6 0L8.398 20.3a.991.991 0 0 1-.355-.793l.885-10.173-.71-2.837c-.5-.015-.913-.045-1.377-.1-2.75-.322-4.565-1.43-4.831-3.77A1 1 0 0 1 3.997 2.4c.133 1.168 1.14 1.783 3.078 2.01.427.05.815.077 1.31.09h7.249c.495-.013.883-.04 1.31-.09 1.939-.227 2.946-.842 3.079-2.01a1 1 0 0 1 1.106-.88zM16.5 10a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11zm0 2c-.556 0-1 .449-1 1.003V14.5h-1.497a1.001 1.001 0 1 0 0 2H15.5v1.497a1.001 1.001 0 1 0 2 0V16.5h1.497a1.001 1.001 0 1 0 0-2H17.5v-1.497c0-.554-.448-1.003-1-1.003z',
    },
});
