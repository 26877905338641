<template>
    <div class="form-group relative"
         :class="{'has-error': error}"
    >
        <label>{{ label }}</label>
        <div class="input-phone">
            <span class="flag" v-text="'🇨🇦'" />
            <span>+01</span>
            <input type="phone"
                   class="form-control input"
                   :value="value"
                   @input="update"
                   :placeholder="placeholder"
                   ref="inputPhone"
            >
        </div>
        <span v-if="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Phone Number',
        },
        value: {
            type: [String, Number],
            default: null,
        },
        placeholder: {
            type: String,
            default: 'placeholder',
        },
        error: {
            type: String,
            default: '',
        },
    },
    methods: {
        update () {
            this.$emit('input', this.$refs.inputPhone.value);
        },
    },
};
</script>

<style lang="scss">
    .input-phone {
        border: 1px solid #CFCACA;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 8px;
        overflow: hidden;

        .input {
            border: unset;
        }

        .flag {
            margin-right: 3px;
        }
    }
</style>
