<template>
    <div class="form-group" :class="{
        'has-error' : error
    }"
    >
        <div>
            <label class="ech-label">{{ label }} <span v-if="!required" class="ech-optional">Optional</span></label>
        </div>
        <select ref="select" :name="name" class="form-control ech-input" :value="value" @change="update">
            <option value="" disabled selected>
                {{ placeholder }}
            </option>
            <option v-for="(item, index) in options" :key="index" :value="item.id">
                {{ item.title }}
            </option>
        </select>

        <span class="error-message">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => ([]),
        },
        required: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'field_name',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
    },

    methods: {
        update () {
            this.$emit('input', parseInt(this.$refs.select.value));
        },
    },
};
</script>
