<i18n>
{
    "en": {
        "backToHome": "Home Page"
    }
}
</i18n>

<template>
    <div class="error-container">
        <div v-if="error.statusCode === 404"
             class="error"
        >
            404
        </div>

        <div v-else-if="error.statusCode === 500"
             class="error"
        >
            500
        </div>

        <b-button variant="outline-light" to="/">
            {{ $t('backToHome') }}
        </b-button>
    </div>
</template>

<script>
export default {
    layout: 'blank',
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
    mounted () {
        const { log } = console;
        log(this.error);
    },
};
</script>

<style lang="scss" scoped>
@import '~vars';

.error-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: linear-gradient($brand2-100, $brand1-100);
}

.error {
    color: $white;
    font-size: 20em;
}
</style>
