<template>
    <div class="offcanvas-bar" :class="getClassList">
        <div class="offcanvas-content">
            <div class="offcanvas-header">
                <slot name="header" />
            </div>

            <div class="offcanvas-body">
                <div>
                    <slot name="body" />
                </div>
            </div>

            <div class="offcanvas-footer">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';

const component = {
    directives: {
        'show-offcanvas': {
            inserted (el) {

            },
        },
    },
    props: ['classList', 'title'],
    computed: {
        getClassList () {
            return this.classList;
        },
    },
    methods: {
        close (object) {
            this.closeOffcanvas(object);
        },
        closeOffcanvas (object) {
            document.querySelector('html').style.overflowY = 'scroll';

            const el = document.querySelector(object.target);

            if (el.classList.contains('active')) {
                el.classList.remove('active');
            }
        },
        offcanvas (object) {
            document.querySelector('html').style.overflowY = 'hidden';

            const target = document.querySelector(object.target);
            target.classList.add('active');
        },
    },
};

Vue.directive('show-offcanvas', {
    inserted: function (el, binding) {
        el.onclick = () => {
            component.methods.offcanvas(binding.value);
        };
    },
});

Vue.directive('close-offcanvas', {
    inserted: function (el, binding) {
        el.onclick = () => {
            component.methods.closeOffcanvas(binding.value);
        };
    },
});

export default component;
</script>
