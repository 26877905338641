import {
    SET_CAMPAIGN_ID,
    SET_UPDATE_CAMPAIGN_INFO,
    SET_PACKAGE_INFO,
    SET_SURVEY_QUESTIONS,
} from '~/scripts/utils/constants/mutations/index';

export const state = () => ({
    campaignId: '',
    updateCampaignInfo: {
        name: '',
        code: '',
        budget: {
            value: 0,
            unit: 'CAD',
        },
        privateNote: '',
        targetCount: 0,
        lifeTime: {
            start: '',
            end: '',
        },
        packageInfoId: '',
        surveyId: '',
    },
    packageInfo: null,
    surveyQuestions: [],
});

export const mutations = {
    [SET_CAMPAIGN_ID] (state, id) {
        state.campaignId = id;
    },
    [SET_UPDATE_CAMPAIGN_INFO] (state, updateCampaignInfo) {
        state.updateCampaignInfo = {
            ...state.updateCampaignInfo,
            ...updateCampaignInfo,
        };
    },
    [SET_PACKAGE_INFO] (state, packageInfo) {
        state.packageInfo = packageInfo;
    },
    [SET_SURVEY_QUESTIONS] (state, question) {
        state.surveyQuestions.push(question);
    },
};
