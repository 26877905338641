<template>
    <div class="file-uploader-container">
        <div class="file-uploader" :style="styleList">
            <div class="file-select">
                <label class="" for="upload-multiple">
                    <span class="ech-icon icon-search" />
                    <h5>UPLOAD</h5>
                    <h6>Sample Image</h6>
                    <input id="upload-multiple" ref="file" type="file" @change="handleFileUpload()">
                </label>
            </div>

            <div class="validation" style="width: calc(126px + 80px);">
                <div class="d-flex justify-content-between">
                    <span class="text-sm text-muted text-left">No. of Images</span>
                    <strong class="text-sm text-left">Up to 4</strong>
                </div>

                <div class="d-flex justify-content-between">
                    <span class="text-sm text-muted text-left">Cover Size</span>
                    <strong class="text-sm text-left">{{ $t('logos.dimensions') }}</strong>
                </div>

                <div class="d-flex justify-content-between">
                    <span class="text-sm text-muted text-left">{{ $t('logos.fileSize') }}</span>
                    <strong class="text-sm text-left">{{ $t('logos.byteSize') }}</strong>
                </div>

                <div class="d-flex justify-content-between">
                    <span class="text-sm text-muted text-left">{{ $t('logos.formats') }}</span>
                    <strong class="text-sm text-left">{{ $t('logos.extensions') }}</strong>
                </div>
            </div>
        </div>

        <div class="uploading" ref="queue" />
    </div>
</template>

<script>
import Vue from 'vue';
import FileUploaderProcessor from './inputFileProcessor';

export default {
    props: {
        styleList: {
            type: Object,
            default: () => ({}),
        },
        crop: {
            type: Object,
            default: () => {
                return {
                    enable: false,
                    ref: null,
                };
            },
        },
    },
    data () {
        return {
            files: '',
        };
    },

    methods: {
        handleFileUpload () {
            try {
                this.files = this.$refs.file.files;

                const FileProcessor = Vue.extend(FileUploaderProcessor);
                const instance = new FileProcessor({
                    propsData: {
                        file: this.files[this.files.length - 1],
                        crop: this.crop,
                    },
                });

                instance.$mount();

                this.$refs.queue.appendChild(instance.$el);
            } catch (e) {

            }
        },
    },
};
</script>
