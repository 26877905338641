import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';

Validator.extend('hexcode', {
    getMessage (field, args) {
        return 'The ' + field + ' value is not Hex color code.';
    },
    validate (value, args) {
        return /^#[0-9A-F]{6}$/i.test(value);
    },
});

Validator.extend('array', {
    getMessage (field, args) {
        return 'The ' + field + ' is not valid.';
    },
    validate (value, args) {
        return value.length;
    },
});

Vue.use(VeeValidate);
