<template>
    <div v-if="!shouldHideOnSelect" class="small-uploader-container" :class="{ 'hasError' : error }">
        <div v-if="label"
             class="label"
        >
            {{ label }}
        </div>
        <div class="small-uploader"
             :class="[className, value && setParentBg ? 'invisible' : 'visible']"
             :style="{ backgroundImage: imageUri ? `url(${imageUri}) !important` : 'none' }"
        >
            <template v-if="(!value && !$attrs.disabled)">
                <!-- TAG (Slot and Prop) -->
                <span class="uploader-tag rounded"
                      v-if="tag"
                >
                    {{ tag }}
                </span>
                <!-- ICON (Slot and Default) -->
                <template v-if="!value">
                    <template v-if="showIcon">
                        <slot v-if="$slots.icon" name="icon" />
                        <span v-else class="icon attach-file m-0" />
                    </template>
                    <!-- DESCRIPTION -->
                    <p class="drag-desc px-2">
                        {{ description }}
                    </p>
                </template>

                <!-- BUTTON -->
                <button class="font-weight-bold"
                        type="button"
                        :for="$attrs.id"
                        v-if="showBrowse"
                        id="file-browse"
                >
                    Browse
                </button>
            </template>
            <!-- INPUT -->
            <input ref="file"
                   @change="handler"
                   :accept="mimeTypes"
                   :id="$attrs.id"
                   :disabled="$attrs.disabled"
                   :class="['zone', {'disabled': $attrs.disabled}]"
                   type="file"
                   name="file"
            >
        </div>
        <div v-if="value && removable" class="remove-button font-weight-bold text-center">
            <button type="button"
                    class="btn-circle text-center border-light"
                    id="reset-picture"
                    @click="resetPicture"
            >
                <span class="icon attach-file bg-danger" />
            </button>
        </div>
        <slot v-if="$slots.footer" name="footer" />

        <div v-if="crop && showCropperView" class="cropper">
            <vueCropper
                ref="cropper"
                :img="image"
                :output-size="0.8"
                :output-type="'png'"
                :auto-crop="true"
                :auto-crop-width="minWidth"
                :auto-crop-height="minHeight"
                :fixed-box="true"
                :fixed="false"
                :fixed-number="[`${minWidth}:${minHeight}`]"
                :full="true"
                :can-scale="canScale"
                :can-move="true"
                :center-box="false"
                :original="false"
                :info-true="false"
                :mode="'contain'"
            />

            <div class="crop-panel">
                <div class="mb-4">
                    <button @click="cropHandler"
                            :data-test-id="`btn-crop-${dataTestId}`"
                            id="save-crop"
                            class="btn btn-small btn-success"
                    >
                        Save
                    </button>
                    <button @click="showCropperView = false"
                            id="close-crop"
                            class="btn btn-small btn-cancel"
                    >
                        {{ $t('common.close') }}
                    </button>
                </div>
                <div class="btn-group-vertical" v-if="canScale">
                    <button class="btn btn-small btn-zoom" @click="changeScale(1)" id="positive-scale">
                        +
                    </button>
                    <button class="btn btn-small btn-zoom" @click="changeScale(-1)" id="negative-scale">
                        -
                    </button>
                </div>
            </div>
        </div>

        <p v-if="showError && error" class="file-error-validation-message">
            Validation photo errors
        </p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ApolloClient from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import uploadPicture from '~/apollo/mutations/common/uploadPicture.gql';
import uploadFile from '~/apollo/mutations/common/uploadFile.gql';

export default {
    props: {
        index: {
            type: Number,
            default: 0,
        },
        label: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: 'Drag a Background Image',
        },
        className: {
            type: String,
            default: '',
        },
        setParentBg: {
            type: Boolean,
            default: true,
        },
        bundleImage: {
            type: Array,
            default: () => ([]),
        },
        tag: {
            type: String,
            default: '',
        },
        shouldHideOnSelect: {
            type: Boolean,
            default: false,
        },
        showBrowse: {
            type: Boolean,
            default: true,
        },
        showIcon: {
            type: Boolean,
            default: true,
        },
        hideOnFill: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Object, String],
            default: () => ({}),
        },
        removable: {
            type: Boolean,
            default: false,
        },
        minWidth: {
            type: Number,
            default: 600,
        },
        minHeight: {
            type: Number,
            default: 600,
        },
        showError: {
            type: Boolean,
            default: false,
        },
        crop: {
            type: Boolean,
            default: false,
        },
        mimeTypes: {
            type: Array,
            default: () => {
                return ['image/png', 'image/jpeg', 'image/jpg'];
            },
        },
        canScale: {
            type: Boolean,
            default: true,
        },
        checkSize: {
            type: Boolean,
            default: true,
        },
        defaultImageUri: {
            type: String,
            default: '',
        },
    },
    data () {
        return {
            file: null,
            image: '',
            error: false,
            showCropperView: false,
            zoomScale: 1,
        };
    },
    computed: {
        imageUri () {
            return this.defaultImageUri || this.value;
        },
    },
    mounted () {
        if (this.setParentBg && this.value) {
            this.$parent.image = this.value;
        }
    },
    methods: {
        ...mapActions(['showAlert']),
        changeScale (zoom) {
            this.$refs.cropper.changeScale(zoom);
        },
        cropHandler () {
            this.$refs.cropper.getCropData((data) => {
                // const dataTrans = new DataTransfer();

                fetch(data)
                    .then((response) => {
                        return response.arrayBuffer();
                    })
                    .then((buffer) => {
                        const file = new File([buffer], 'out.png', {
                            type: 'image/png',
                        });
                        // dataTrans.items.add(file);
                        this.file = file;
                    })
                    .then(() => {
                        this.$emit('input', data);
                        this.showCropperView = false;
                    });
            });
        },
        handler (e) {
            this.error = false;
            this.file = this.$refs.file.files[0];
            this.fileReader();
            e.target.value = ''; // for uploading the same file again
        },
        async fileReader () {
            const reader = new FileReader();

            try {
                if (!this.mimeTypes.includes(this.file.type)) return this.showAlert('Oops, File is not allowed');
                reader.onload = function () {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                        if (this.checkSize) {
                            if (this.minWidth !== image.width || this.minHeight !== image.height) {
                                this.error = true;
                                this.showAlert(`Oops, Check your image size [${image.width}x${image.height}]`);
                                throw new Error('Error on size');
                            }
                        }

                        if (this.crop) {
                            this.image = reader.result;
                            this.showCropperView = true;
                        }

                        if (!this.crop) {
                            this.$emit('input', reader.result);
                        }

                        if (this.setParentBg) {
                            this.$parent.image = reader.result;
                        }
                    };
                }.bind(this);

                reader.readAsDataURL(this.file);
            } catch (e) {}
            // this.$emit('fileChanged', this.file);
        },
        resetPicture (e) {
            e.preventDefault();
            this.$emit('input', null);
            if (this.setParentBg) {
                this.$parent.image = '';
            }
        },
        uploadPicture (selectedFile) {
            // Parameter is not valid
            try {
                // https://marvinrabe.de/graphql-vue-upload/
                const client = new ApolloClient({
                    link: createUploadLink({
                        uri: process.env.API_URL,
                    }),
                    cache: new InMemoryCache(),
                });

                const file = selectedFile || this.file;

                if (!file) return false;

                return client.mutate({
                    mutation: uploadPicture,
                    variables: {
                        file: file,
                        name: file.name,
                    },
                    context: {
                        hasUpload: true,
                    },
                }).then(response => {
                    return response;
                });
            } catch (error) {
                console.log(error) // eslint-disable-line
            }
        },
        uploadFile () {
            try {
                // https://marvinrabe.de/graphql-vue-upload/
                const client = new ApolloClient({
                    link: createUploadLink({
                        uri: process.env.API_URL,
                    }),
                    cache: new InMemoryCache(),
                });

                return client.mutate({
                    mutation: uploadFile,
                    variables: {
                        file: this.file,
                        name: this.file.name,
                    },
                    context: {
                        hasUpload: true,
                    },
                }).then(response => {
                    return response;
                });
            } catch (error) {
                console.log(error) // eslint-disable-line
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.small-uploader-container {

    &.hasError {

        .small-uploader {
            border-color: red;
        }
    }

    .file-error-validation-message {
        color: red;
    }
}

img {
    display: block;

    /* This rule is very important, please don't ignore this */
    max-width: 100%;
}

.cropper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10000;

    .crop-panel {
        position: fixed;
        top: 20px;
        right: 20px;
        text-align: right;

        .btn-zoom {
            background-color: #EEE;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                font-size: 20px;
                background-color: #CCC;
            }
        }
    }
}
</style>
