<template>
    <div class="form-group relative"
         :class="{'has-error': error}"
    >
        <div class="ech-label-area">
            <label class="label">
                <span>
                    {{ label }}
                    <span v-if="!required" :class="optionalClassName">Optional</span>
                </span>
            </label>
            <span class="ech-input-length-counter"
                  v-if="lengthCounter"
            >
                <span v-if="maxLength > 0">
                    <span :class="lengthClass">{{ maxLength - length }}</span>
                    /
                    <span class="secondary-text-100">{{ maxLength }}</span>
                </span>
                {{ $t('charactersLeft') }}
            </span>
        </div>
        <textarea class="form-control textarea"
                  :class="className"
                  ref="textarea"
                  :value="value"
                  :placeholder="placeholder"
                  @input="update"
                  :rows="rows"
                  :data-test-id="dataTestId"
                  :disabled="disabled"
                  :maxlength="maxLength"
        />

        <span v-show="error" class="error-message">{{ error }}</span>
    </div>
</template>

<script>
export default {
    props: {
        dataTestId: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        optionalClassName: {
            type: String,
            default: 'ech-optional',
        },
        placeholder: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        rows: {
            type: [Number, String],
            default: 2,
        },
        className: {
            type: String,
            default: '',
        },
        error: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: [Number, String],
            default: 500,
        },
        lengthCounter: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        lengthClass () {
            if ((this.maxLength - this.length) <= Math.floor(0.1 * this.maxLength)) {
                return 'brand-1-100';
            }
            return 'green-100';
        },
        length () {
            return (this.value || '').length;
        },
    },
    methods: {
        update () {
            this.$emit('input', this.$refs.textarea.value);
        },
    },
};
</script>
